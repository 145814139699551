<template>
  <div class="app register">
    <div class="register-in">
      <div class="view">
        <p class="title">{{ t('register.title') }}</p>
        <div class="container">
          <div class="inputs">
            <p class="tips" v-html="emailTips"></p>
            <div class="email input-indent">
              <input type="text" :placeholder="t('register.inputEmail')" maxlength="25" v-model="email" @focus="emailTips = '&nbsp;'">
            </div>
            <p class="tips" v-html="captchaTips"></p>
            <div class="captcha input-indent">
              <input type="text" :placeholder="t('register.inputCaptcha')" maxlength="10" v-model="captcha" @focus="captchaTips = '&nbsp;'">
              <em class="get-captcha" :class="{sending: mailSending, waiting: mailWaiting}" @click="sendEmail()">{{ captchaText }}</em>
            </div>
            <p class="tips" v-html="passTips"></p>
            <div class="pass input-indent">
              <input :type="passIptType" :placeholder="t('register.inputPass')" maxlength="25" v-model="pass" @focus="passTips = '&nbsp;'">
              <i class="eye" :class="passIptType === 'text' ? 'show' : ''" @mousedown="passIptType = 'text'" @mouseup="passIptType = 'password'"></i>
            </div>
            <p class="tips" v-html="confirmTips"></p>
            <div class="confirm input-indent">
              <input :type="confirmIptType" :placeholder="t('register.inputConfirmPass')" maxlength="25" v-model="confirm" @focus="confirmTips = '&nbsp;'">
              <i class="eye" :class="confirmIptType === 'text' ? 'show' : ''" @mousedown="confirmIptType = 'text'" @mouseup="confirmIptType = 'password'"></i>
            </div>
          </div>
          <div class="submit" :style="{backgroundColor: registering ? 'rgba(96, 183, 255, 0.5)' : 'rgba(96, 183, 255, 1)'}" v-text="registering ? t('register.registering') : t('register.register')" @click="register()"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useDialog } from '../utils/dialog.js'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, reactive, toRefs } from 'vue'
import { mailApi, registerApi, authVerifyApi } from '../api/index.js'

export default {
  name: 'Register',
  setup() {
    const { t } = useI18n()
    const Dialog = useDialog()
    const Route = useRoute()
    const Router = useRouter()
    const data = reactive({
      auth: Route.query.auth,

      email: '',
      captcha: '',
      pass: '',
      confirm: '',

      emailTips: '&nbsp;',
      captchaTips: '&nbsp;',
      passTips: '&nbsp;',
      confirmTips: '&nbsp;',

      passIptType: 'password',
      confirmIptType: 'password',

      captchaText: t('register.captchaText'),
      countDown: 60,

      mailSending: false,
      mailWaiting: false,
      registering: false
    })

    onMounted(() => {
      if (data.auth === undefined) {
        Router.push({ name: 'Login' })
        return
      }

      authVerify()
    })

    // 发邮件
    const sendEmail = () => {
      if (data.mailWaiting) return
      if (!/^([A-Za-z0-9_\-\.]{1,65})+\@(qq|vip.qq|163|126|yeah|sina|gmail|icloud|foxmail|outlook)+\.([A-Za-z]{2,4})$/.test(data.email)) {
        data.captchaTips = t('register.mailApi.mailLimit')
        return
      }

      data.mailSending = true
      data.mailWaiting = true
      data.captchaText = ''
      mailApi({
        auth: data.auth,
        email: data.email,
        source: 1
      }).then(succ => {
        if (succ.code !== 10000) {
          resetForm()
          let errMsg = ''
          if (succ.code === 10001 || succ.code === 10002 || succ.code === 10004 || succ.code === 10005 || succ.code === 10006 || succ.code === 10007) {
            errMsg = t('register.mailApi.paramError')
          } else if (succ.code === 10003) {
            errMsg = t('register.mailApi.linkExpired')
          } else if (succ.code === 10009) {
            errMsg = t('register.mailApi.mailExist')
          } else if (succ.code === 10010) {
            errMsg = t('register.mailApi.overTimes')
          } else if (succ.code === 10010) {
            errMsg = t('register.mailApi.busy')
          } else if (succ.code === 10013) {
            errMsg = t('register.mailApi.failed')
          }
          Dialog({
            content: errMsg,
            btns: [{ text: t('ok') }]
          })
          return
        }
        Dialog({
          content: t('register.mailApi.success'),
          btns: [{ text: t('ok') }]
        })

        data.mailSending = false
        data.captchaText = '60s'
        const itv = setInterval(() => {
          if (data.countDown === 0) {
            resetForm()
            clearInterval(itv)
            return
          }
          data.countDown--
          data.captchaText = data.countDown + 's'
        }, 1000)
      })
    }

    // 注册
    const register = () => {
      if (data.registering) return

      if (data.email === '') {
        data.emailTips = t('register.registerApi.emailCanNotEmpty')
        return
      }
      if (data.captcha === '') {
        data.captchaTips = t('register.registerApi.captchaCanNotEmpty')
        return
      }
      if (data.pass === '') {
        data.passTips = t('register.registerApi.passCanNotEmpty')
        return
      }
      if (data.confirm === '') {
        data.confirmTips = t('register.registerApi.confirmPassCanNotEmpty')
        return
      }
      if (data.pass !== data.confirm) {
        data.passTips = t('register.registerApi.passAndConfirmPassNotSame')
        data.confirmTips = t('register.registerApi.passAndConfirmPassNotSame')
        return
      }

      data.registering = true
      registerApi({
        auth: data.auth,
        email: data.email,
        captcha: data.captcha,
        pass: data.pass,
        confirm: data.confirm
      }).then(succ => {
        if (succ.code !== 10000) {
          let errMsg = ''
          if (succ.code === 10001 || succ.code === 10002 || succ.code === 10005 || succ.code === 10007 || succ.code === 10008 || succ.code === 10011 || succ.code === 10013) {
            errMsg = t('register.registerApi.paramError')
          } else if (succ.code === 10003) {
            errMsg = t('register.registerApi.linkExpired')
          } else if (succ.code === 10004) {
            errMsg = t('register.registerApi.registered')
          } else if (succ.code === 10006) {
            errMsg = t('register.registerApi.captchaError')
          } else if (succ.code === 10009) {
            errMsg = t('register.registerApi.captchaGetMailAndConfirmMailNotSame')
          } else if (succ.code === 10010) {
            errMsg = t('register.registerApi.mailExist')
          } else if (succ.code === 10012) {
            errMsg = t('register.registerApi.passWeak')
          } else if (succ.code === 10014) {
            errMsg = t('register.registerApi.passAndConfirmPassNotSame')
          } else if (succ.code === 10015) {
            errMsg = t('register.registerApi.failed')
          }
          Dialog({
            content: errMsg,
            btns: [{ text: t('ok') }]
          })
          return
        }
        Dialog({
          content: t('register.registerApi.success'),
          closeable: false,
          btns: [{ text: t('ok'), click: () => Router.push({ name: 'Login' }) }]
        })
      }).finally(() => {
        data.registering = false
      })
    }

    // 认证参数是否过期
    const authVerify = () => {
      authVerifyApi({
        auth: data.auth
      }).then(succ => {
        if (succ.code !== 10000) Router.push({ name: 'Login' })
      })
    }

    // 重置表单
    const resetForm = () => {
      data.mailSending = false
      data.mailWaiting = false
      data.countDown = 60
      data.captchaText = t('register.captchaText')
    }

    return { ...toRefs(data), t, sendEmail, register }
  }
}
</script>

<style lang="scss" scoped>
.register {
  display: table;
  &-in {
    display: table-cell;
    vertical-align: middle;
    .view {
      width: 600px;
      margin: -6% auto 0;
      padding: 20px 20px 30px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.2);
      .title {
        height: 1.2em;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .container {
        padding: 0 25px;
        .inputs {
          .tips {
            color: rgb(255, 75, 75);
            font-size: 12px;
            padding-top: 2px;
            line-height: 1.5em;
          }
          div {
            height: 40px;
            overflow: hidden;
            border-radius: 5px;
            position: relative;
            border: 1px solid #999;
            .eye {
              width: 40px;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              cursor: pointer;
              background: no-repeat center center / 60%;
              background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAMAAADypuvZAAAAP1BMVEVHcExWWmBVWmBVWmBcZ2xVWl9XW2BbX2ZVWV9XXGFYXWNWWmBVWl9WWmBVWV9VWV9VWV9WW2BVWmBWWmBVWV9P2DjOAAAAFHRSTlMAmLqqCMY8EfguH4rgXfDU6Et6bp7sQ2IAAAHGSURBVEjH7VTXlq0gFJPei5j//9aL2FDnFN9mrTv7RUUCISR7GP7qPy9O6XMQQXqMCdDmKcZohK8mKmuksby9JpDP8wMRGkt5ViRD4h90CrQBohci+QWr35NTJdc5tEi1DtjAZpgYX2OmWP8HdVrGg7l5+IV8VgBUXgYpBB/4lKHdTwcLGnlnYVyKMRFTkG3bkNTN1A1TALaPOuy1rSM9vLkZBVPHCUQqLuug30kpgWgu++hDILfxFNDdvXJ2Rk09xuqVk4MfgW4eW0+48EVvLgfWniO0rB+kTwj2iPAE120r0HS3cT6lge9vLaMcaenvIEMtK80bcuhzSLAQVLoTbgcReHUHiZX6LEO0V3pb7s706l2rQxXBz0LsuTsJMR533VxJeS952HIne8ml3mVo83KPcvVOF2mqd46NZLwk2GSk41yp+nC10cG7Gppdm0c6PC71bleyYXhdwN1DSzeb1xO6LRrD4fGfQ180YlFr7k4l6Zvo1n/Rkd6VrTnNw+V1k5iXrCzDtqqSE9NYCLypce1aOQnhY3unk/rQKUXtLYX5VTwtSLAfu6tbbDrw1pXVVx285e5hLbl7WPRmlC+KCTX81a+sf+nIGvtVRlr/AAAAAElFTkSuQmCC');
            }
            .show {
              background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAMAAADypuvZAAAAPFBMVEVHcExXW2FVWV9VWV9dY2hXXGJaXmVVWmBVWV9WWmBWWmBVWmBVWmBXW2NmgoJWWmBWW2BVWWBVWV9VWV+/qypcAAAAE3RSTlMAPOziCy8U98WUh3W1IwRfSaLVyk2GCAAAAWtJREFUSMftlct2gzAMRP2WjV/A/f9/7aIETEtINt30RCssa8R4ZMlKfexj/8BC9dpX9358T1H4Nlsm/RrhegHA2BhXKwAm3eNcNiAlz2Fz1D6tQJyfY7KB2MMPr58MRH8NqRGiviZgkald7HTB7izqtIqsaU/vEsTwmxosD2+bNvFIu+jaYn9STJAf363Aop3zCeKOChFzRk3IPCzMvKdfdndbTqjW4cBUORYajrhWsMe5NPTxr8tIO6kRFR8aupVpoBoZdPfYARTsHplYxxoYBm0bcqoL26aTQ7hXoLhTz5j6Fj2VByXKUI4bIdo8ihzsiHoquT6fo1qKe1Xc2YzyK6WqYa3316jLSFsppVpd9/SXF9YlTsXczlWGa/6rNWaL9It+alkwOVw1oS43rVtAkr8aNZLb0yGhC2BT91uI07kImCncziOfLIDYNUZrAIj9jZmpc7GbeBJTD6q9OWVd8NrX8HmePvZ39gWP9hWVFaKWqgAAAABJRU5ErkJggg==');
            }
          }
          .captcha {
            .get-captcha {
              color: #fff;
              width: 5em;
              box-sizing: content-box;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              cursor: pointer;
              padding: 0 15px;
              line-height: 40px;
              text-align: center;
              background: no-repeat center center / cover;
              background-color: #60b7ff;
            }
            .sending {
              cursor: not-allowed;
              background-color: #909090;
              background-image: url('/img/loading-dot.svg');
            }
            .waiting {
              cursor: not-allowed;
              background-color: #909090;
            }
          }
        }
        .submit {
          color: #fff;
          width: 250px;
          cursor: pointer;
          line-height: 40px;
          text-align: center;
          margin: 20px auto 0;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
